
import userStatuses from "@/constants/userStatuses";
import { AppUpdateModule } from "@/store/modules/appUpdate";
import { DrawersModule } from "@/store/modules/drawers";
import { FriendsModule, FriendStatus } from "@/store/modules/friends";
import { LastSeenServerChannelsModule } from "@/store/modules/lastSeenServerChannel";
import { LastSelectedServersModule } from "@/store/modules/lastSelectedServer";
import { MeModule } from "@/store/modules/me";
import { NotificationsModule } from "@/store/modules/notifications";
import { PopoutsModule } from "@/store/modules/popouts";
import { PresencesModule } from "@/store/modules/presences";
import { ReactiveLocalStorageModule } from "@/store/modules/reactiveLocalStorage";
import { voiceChannelModule } from "@/store/modules/voiceChannels";
import { useWindowProperties } from "@/utils/windowProperties";
import { defineComponent, defineAsyncComponent } from "vue";
const AvatarImage = defineAsyncComponent(
  () => import("@/components/AvatarImage.vue")
);
const NavBarInCall = defineAsyncComponent(
  () => import("@/components/NavBarInCall.vue")
);
export default defineComponent({
  name: "NavBar",
  components: { AvatarImage, NavBarInCall },
  computed: {
    isInCall() {
      return voiceChannelModule.joinedChannelId;
    },
    isMobileWidth() {
      return useWindowProperties().isMobileWidth;
    },
    showSettings(): any {
      return ReactiveLocalStorageModule.getStore("showSettingsInNavigation");
    },
    currentTab(): any {
      return this.$route.path.split("/")[2] || "";
    },
    me(): any {
      return MeModule.user;
    },
    isAdmin(): any {
      return this.me.type === "CREATOR" || this.me.type === "ADMIN";
    },
    serverMentioned(): any {
      return LastSeenServerChannelsModule.allServerNotifications.find(
        (c) => c.mentioned
      );
    },
    serverNotificationExists(): any {
      return LastSeenServerChannelsModule.allServerNotifications.length > 0;
    },
    dmNotificationExists(): any {
      return NotificationsModule.allDMNotifications.length > 0;
    },
    friendRequestExists(): any {
      return this.friends.find((f) => f.status === FriendStatus.PENDING);
    },
    friends(): any {
      return FriendsModule.friendsWithUser;
    },
    presence(): any {
      if (!this.me?.id || !MeModule.connected) return userStatuses[0];
      const presence = PresencesModule.getPresence(this.me.id);
      return userStatuses[presence || 0];
    },
    pinned(): any {
      const routeName = this.$route.name as string;
      return !routeName?.endsWith("message-area");
    },
    leftDrawerOpened(): any {
      return DrawersModule.leftDrawer;
    },
    updateAvailable(): any {
      return AppUpdateModule.updateAvailable;
    },
    isProfileOpened(): any {
      return PopoutsModule.isOpened("floating-profile-card");
    },
  },
  methods: {
    changeTab(name: string) {
      const selectedServerID = this.lastSelectedServerID();
      const serverChannelID = LastSelectedServersModule.lastServerChannelID(
        selectedServerID || ""
      );

      const selectedDmChannelId = this.lastSelectedDMChannelID();
      let path = name;
      if (this.currentTab === name) return;
      if (name === "servers" && selectedServerID && serverChannelID) {
        path += `/${selectedServerID}/${serverChannelID}`;
      }
      if (name === "dms" && selectedDmChannelId) {
        path += `/${selectedDmChannelId}`;
      }
      this.$router.push("/app/" + path);
    },
    updateAvailableClick() {
      PopoutsModule.ShowPopout({
        id: "update-popout",
        component: "UpdatePopout",
      });
    },
    lastSelectedServerID(): string | null {
      return localStorage.getItem("lastSelectedServerID");
    },
    lastSelectedDMChannelID(): string | null {
      return localStorage.getItem("lastSelectedDMChannelID");
    },
    showCardPopup() {
      PopoutsModule.ShowPopout({
        id: "floating-profile-card",
        component: "FloatingProfileCard",
        toggle: true,
      });
    },
  },
});
